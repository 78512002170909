import React, { useState } from 'react';
import styled from 'styled-components';
// import bffFooterData from '../../../../bff-data/footer.json';
import bffFooterData from '../../../../build/footerDev.json';

/* eslint-disable import/no-extraneous-dependencies */
const pmRr = require('@postman/rr');
const pmUuid = require('@postman/uuid');
/* eslint-enable */ // root relative link

const footerData = pmRr(bffFooterData);

const Footer = styled.footer`
  background-color: #0d1629;
  color: #fff;
  padding-top: ${(props) => props.paddingTop || '30px'};
  padding-bottom: 30px;
  border-top: 10px solid #ff6c37;
`;
const FooterLinkStyles = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid white;
`;
const Column = styled.ul`
  padding-left: 0;
`;

const ColumnRow = styled.li`
  list-style-type: none;
  margin-bottom: 0;
  padding: 5px 0;
`;

const Copyright = styled.span`
  color: #fff !important;
  font-size: 14px;
`;

const FooterColTitle = styled.h2`
  font-size: 1.6rem !important;
  line-height: 1.4;
  margin-bottom: 8px;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 600;
  color: #fff;
  font-feature-settings: 'calt' 0;
`;
const SubFooter = styled.div`
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 20px;
  .choice-link {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
`;
const SocialCol = styled.div`
  .social-icon svg {
    fill: white; /* Default color is white */
    transition: fill 0.2s ease-in-out;
  }

  .social-icon:hover svg {
    fill: var(--original-fill); /* Use the original color on hover */
  }
`;
const FooterImg = styled.img`
  width: 100px;
  height: auto;
  margin: 0 auto;
  display: block;
`;
const triggerGA = (category, label) => {
  return category && label && window.pmt && window.pmt('ga', ['send', 'event', category, 'Click', label]);
};

// Helper function for rel attribute in link or button
export function relStringGenerator(target) {
  if (target === 'new-tab-external-company') {
    return 'noopener noreferrer';
  }
  if (target === 'new-tab-external-nofollow') {
    return 'noopener noreferrer nofollow';
  }
  if (target === 'new-tab-postman') {
    return 'noopener';
  }
  return null;
}

// Helper function for target attribute in link or button
export function targetStringGenerator(target) {
  if (target === 'new-tab-external-company' || target === 'new-tab-external-nofollow' || target === 'new-tab-postman') {
    return '_blank';
  }
  return null;
}
const socialIcons = [
  {
    svg: "<svg width='16' height='14' viewBox='0 0 16 14' fill='#1D9BF0' xmlns='http://www.w3.org/2000/svg' ><path d='M14.1439 3.76852C14.1534 3.9068 14.1534 4.04509 14.1534 4.18465C14.1534 8.43705 10.9162 13.3414 4.99671 13.3414V13.3388C3.24808 13.3414 1.53578 12.8405 0.0637207 11.8961C0.317985 11.9267 0.573525 11.9419 0.829701 11.9426C2.27882 11.9439 3.68651 11.4576 4.82656 10.5623C3.44946 10.5362 2.24186 9.63827 1.82 8.32744C2.3024 8.42048 2.79946 8.40136 3.27294 8.272C1.77156 7.96867 0.691417 6.64955 0.691417 5.11759C0.691417 5.10357 0.691417 5.09018 0.691417 5.0768C1.13877 5.32597 1.63965 5.46425 2.15201 5.47955C0.737936 4.5345 0.302054 2.65332 1.15598 1.18254C2.7899 3.19308 5.20063 4.41533 7.78853 4.54469C7.52916 3.42695 7.88348 2.25568 8.71955 1.46994C10.0157 0.251509 12.0543 0.31396 13.2727 1.6095C13.9935 1.46739 14.6843 1.20293 15.3164 0.828224C15.0762 1.57318 14.5734 2.20597 13.9017 2.60808C14.5396 2.53288 15.1628 2.3621 15.7498 2.10146C15.3177 2.74891 14.7735 3.31288 14.1439 3.76852Z' /></svg>",
    icon: 'https://voyager.postman.com/logo/external/twtr-logo-blue.svg',
    title: 'Twitter',
    url: 'https://twitter.com/getpostman',
    hrefType: 'external-other',
    category: 'global-footer',
    label: 'twitter',
    ariaLabel: 'Follow Postman on Twitter'
  },
  {
    svg: '<svg width="16" height="16" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" fill="#0e76a8"><path d="M5.134 7.363H.537v14.044h4.597zM5.437 3.019C5.407 1.642 4.44.593 2.866.593 1.293.593.265 1.642.265 3.019c0 1.348.998 2.427 2.541 2.427h.03c1.603 0 2.601-1.079 2.601-2.427zM21.714 13.354c0-4.313-2.268-6.32-5.293-6.32-2.44 0-3.534 1.362-4.144 2.319v-1.99H7.68c.06 1.318 0 14.044 0 14.044h4.598v-7.843c0-.42.03-.839.151-1.139.333-.839 1.09-1.707 2.36-1.707 1.664 0 2.329 1.288 2.329 3.175v7.514h4.597v-8.053z" ></path></svg>',
    icon: 'https://voyager.postman.com/logo/external/lnkdin-icon.svg',
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/postman-platform',
    hrefType: 'external-other',
    category: 'global-footer',
    label: 'linkedin',
    ariaLabel: 'Follow Postman on Linked In'
  },
  {
    svg: '<svg height="16" class="octicon octicon-mark-github" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true" fill="#24292f"><path  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path></svg>',
    icon: 'https://voyager.postman.com/logo/external/github-icon.svg',
    title: 'GitHub',
    url: 'https://github.com/postmanlabs',
    hrefType: 'external-other',
    category: 'global-footer',
    label: 'github',
    ariaLabel: 'Contribute to Postman on Git Hub'
  },
  {
    svg: "<svg height='16' width='16' enable-background='new 0 0 30 21.14' viewBox='0 0 30 21.14' xmlns='http://www.w3.org/2000/svg' fill='#c4302b'><path d='m29.37 3.3c-.35-1.3-1.36-2.32-2.65-2.67-2.34-.63-11.72-.63-11.72-.63s-9.38 0-11.72.63c-1.29.35-2.31 1.37-2.65 2.67-.63 2.36-.63 7.27-.63 7.27s0 4.91.63 7.27c.35 1.3 1.36 2.32 2.65 2.67 2.34.63 11.72.63 11.72.63s9.38 0 11.72-.63c1.29-.35 2.31-1.37 2.65-2.67.63-2.36.63-7.27.63-7.27s0-4.91-.63-7.27zm-17.44 11.73v-8.92l7.84 4.46z'></path></svg>",
    icon: 'https://voyager.postman.com/logo/external/yt-icon.svg',
    title: 'YouTube',
    url: 'https://www.youtube.com/c/Postman',
    hrefType: 'external-other',
    category: 'global-footer',
    label: 'youtube',
    ariaLabel: 'Watch our Postman You Tube channel'
  },
  {
    svg: "<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#9146FF' viewBox='0 0 16 16'><path d='M3.857 0 1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0zm9.714 7.429-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142z'/><path d='M11.857 3.143h-1.143V6.57h1.143zm-3.143 0H7.571V6.57h1.143z'/></svg>",
    icon: 'https://voyager.postman.com/logo/external/twitch-icon.svg',
    title: 'Twitch',
    url: 'https://www.twitch.tv/getpostman',
    hrefType: 'external-other',
    category: 'global-footer',
    label: 'twitch',
    ariaLabel: 'Follow Postman on Twitch'
  }
];
function FooterSota() {
  const [data] = useState(footerData);
  const columns = data.items.slice(0, 5);

  return (
    <Footer>
      <div className="container small">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {/* First column */}
              <div className="col-12 col-lg-2 order-lg-first order-last my-4 my-lg-0">
                <FooterImg src="https://voyager.postman.com/logo/postman-logo-orange-stacked.svg" alt={data.alt} fetchpriority="low" loading="lazy" />
              </div>
              {/* Second column */}
              <div className="col-6 col-md-2 mb-2 mb-md-0">
                {columns.slice(0, 1).map((item) => (
                  <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                    <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                    <Column>
                      {(item.items &&
                        item.items.map((link) => (
                          <ColumnRow key={pmUuid()}>
                            <FooterLinkStyles
                              href={link.url}
                              rel={relStringGenerator(link.target)}
                              target={targetStringGenerator(link.target)}
                              aria-label={link.ariaLabel}
                              onClick={() => {
                                triggerGA(link.category, link.label);
                              }}
                            >
                              {link.title}
                            </FooterLinkStyles>
                          </ColumnRow>
                        ))) ||
                        ''}
                    </Column>
                  </nav>
                ))}
              </div>
              <div className="col-6 col-md-2">
                {/* Third column - stacked - top */}
                {columns.slice(1, 2).map((item) => (
                  <div key={pmUuid()}>
                    <nav aria-labelledby={item.arialabelledby} style={{ marginBottom: `${37}px` }}>
                      <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                      <Column>
                        {(item.items &&
                          item.items.map((link) => (
                            <ColumnRow key={pmUuid()}>
                              <FooterLinkStyles
                                href={link.url}
                                rel={relStringGenerator(link.target)}
                                target={targetStringGenerator(link.target)}
                                aria-label={link.ariaLabel}
                                onClick={() => {
                                  triggerGA(link.category, link.label);
                                }}
                              >
                                {link.title}
                              </FooterLinkStyles>
                            </ColumnRow>
                          ))) ||
                          ''}
                      </Column>
                    </nav>
                  </div>
                ))}
              </div>
              <div className="col-6 col-md-2">
                {/* Third column - stacked - bottom */}
                {columns.slice(2, 3).map((item) => (
                  <div key={pmUuid()}>
                    <nav aria-labelledby={item.arialabelledby} className="mb-5">
                      <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                      <Column>
                        {(item.items &&
                          item.items.map((link) => (
                            <ColumnRow key={pmUuid()}>
                              <FooterLinkStyles
                                href={link.url}
                                rel={relStringGenerator(link.target)}
                                target={targetStringGenerator(link.target)}
                                aria-label={link.ariaLabel}
                                onClick={() => {
                                  triggerGA(link.category, link.label);
                                }}
                              >
                                {link.title}
                              </FooterLinkStyles>
                            </ColumnRow>
                          ))) ||
                          ''}
                      </Column>
                    </nav>
                  </div>
                ))}
              </div>
              {/* Fourth Column API Categories */}
              <div className="col-6 col-md-2">
                {columns.slice(3, 4).map((item) => (
                  <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                    <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                    <Column>
                      {(item.items &&
                        item.items.map((link) => (
                          <ColumnRow key={pmUuid()}>
                            <FooterLinkStyles
                              href={link.url}
                              rel={relStringGenerator(link.target)}
                              target={targetStringGenerator(link.target)}
                              aria-label={link.ariaLabel}
                              onClick={() => {
                                triggerGA(link.category, link.label);
                              }}
                            >
                              {link.title}
                            </FooterLinkStyles>
                          </ColumnRow>
                        ))) ||
                        ''}
                    </Column>
                  </nav>
                ))}
              </div>
              {/* Fifth Column Resources */}
              <div className="col-6 col-md-2">
                {columns.slice(4, 5).map((item) => (
                  <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                    <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                    <Column>
                      {(item.items &&
                        item.items.map((link) => (
                          <ColumnRow key={pmUuid()}>
                            <FooterLinkStyles
                              href={link.url}
                              rel={relStringGenerator(link.target)}
                              target={targetStringGenerator(link.target)}
                              aria-label={link.ariaLabel}
                              onClick={() => {
                                triggerGA(link.category, link.label);
                              }}
                            >
                              {link.title}
                            </FooterLinkStyles>
                          </ColumnRow>
                        ))) ||
                        ''}
                    </Column>
                  </nav>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* column social */}
        <SocialCol className="row justify-content-center justify-content-lg-end">
          {socialIcons.map((social) => {
            const originalFillMatch = social.svg.match(/fill=['"](#?[0-9a-fA-F]{3,6})['"]/);
            const originalFill = originalFillMatch ? originalFillMatch[1] : 'currentColor';

            return (
              <span key={pmUuid()} style={{ margin: '0 8px' }}>
                <a href={social.url} rel="noopener noreferrer" target="_blank" aria-label={social.ariaLabel}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: social.svg.replace(/fill=['"]#[0-9a-fA-F]{3,6}['"]/g, 'fill="white"')
                    }}
                    className="social-icon"
                    style={{ '--original-fill': originalFill }} // Pass the original fill color
                  />
                </a>
              </span>
            );
          })}
        </SocialCol>
        <SubFooter className="row">
          <div className="col-12 col-lg-2 text-center text-lg-left mb-2 mb-lg-0 pl-0">
            <a className="choice-link" href="/downloads/">
              Download Postman
            </a>
          </div>
          <div className="col-12 col-lg-2 text-center text-lg-left mb-2 mb-lg-0 pl-0">
            <a className="choice-link d-inline-block" href="https://www.postman.com/legal/privacy-choices/">
              Your privacy choices
            </a>
            <img src="https://voyager.postman.com/icon/privacyoptions.svg" alt="" width={24} height={10} className="" />
          </div>
          <div className="col-12 col-lg-8 text-center text-lg-right pr-0">
            <Copyright>&copy; {new Date().getFullYear()} Postman, Inc.</Copyright>
          </div>
        </SubFooter>
        {/*  eslint-enable */}
      </div>
    </Footer>
  );
}

export default FooterSota;
